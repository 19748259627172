import { useEffect, useState } from "react";

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";

import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";

import { loginRequest } from "../authConfig";
import { WelcomeMat } from "../components/WelcomeMat";
import { TemplateList } from "../components/TemplateList";
import { FileDragNDrop } from '../components/FileDragNDrop';

import { IconSettings, Spinner, AlertContainer, Alert, Icon } from '@salesforce/design-system-react';

import { protectedResources } from "../authConfig";
import { postMultiPartApiWithToken, putOneDriveFileWithToken, getOneDriveFileWithToken, callApiWithToken } from "../fetch";



/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated 
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use 
 * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
 * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export function Dubber(props) {
	const authRequest = {
		...loginRequest
	};

	const errorMsgAddon = ', please try again later. If this continues please contact the lab via the feedback details below.';

	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount(accounts[0] || {});

	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [fileToBeDubbed, setFileToBeDubbed] = useState(null);
	const [dubbResponse, setDubbResponse] = useState(null);
	const [graphFile, setGraphFile] = useState(null);
	const [graphFileUrl, setGraphFileUrl] = useState(null);
	const [isDubbing, setIsDubbing] = useState(false);
	const [profileData, setProfileData] = useState(null);
	const [anError, setAnError] = useState(null);


	useEffect(() => {
		if (account && inProgress === "none" && !profileData) {
			instance.acquireTokenSilent({
				scopes: protectedResources.getGraphProfile.scopes,
				account: account
			}).then((response) => {
				callApiWithToken(response.accessToken, protectedResources.getGraphProfile.endpoint)
					.then(response => setProfileData(response));
			}).catch((error) => {
				// in case if silent token acquisition fails, fallback to an interactive method
				if (error instanceof InteractionRequiredAuthError) {
					if (account && inProgress === "none") {
						instance.acquireTokenPopup({
							scopes: protectedResources.getGraphProfile.scopes,
						}).then((response) => {
							callApiWithToken(response.accessToken, protectedResources.getGraphProfile.endpoint)
								.then(response => setProfileData(response));
						}).catch(error => console.log(error));
					}
				}
			});
		} else {
			console.info(profileData);
		}
	}, [account, inProgress, instance, profileData]);

	useEffect(() => {
		if (graphFileUrl && graphFileUrl.webUrl) {

			let url = graphFileUrl.webUrl.replace('action=default', 'action=view');
			//url = `ms-word:ofe|u|${url}`;

			try {
				let docName = url.split('file=')[1].split('.docx')[0] + '.docx';
				url = url.split('_layouts')[0]
				url = `ms-word:ofe|u|${url}Documents/aglabs-dubbed-resumes/${docName}`
			} catch (ex) {
				console.log(ex);
			}
			window.open(url, "_blank")
			setGraphFileUrl(null);
		}
		setIsDubbing(false);
	}
		, [graphFileUrl])

	useEffect(() => {
		if (graphFile) {
			console.info(graphFile)

			instance.acquireTokenSilent({
				scopes: protectedResources.getGraphFile.scopes,
				account: account
			}).then((response) => {
				getOneDriveFileWithToken(response.accessToken, protectedResources.getGraphFile.endpoint, graphFile.id)
					.then(response => setGraphFileUrl(response));
			}).catch((error) => {
				// in case if silent token acquisition fails, fallback to an interactive method
				if (error instanceof InteractionRequiredAuthError) {
					if (account && inProgress === "none") {
						instance.acquireTokenPopup({
							scopes: protectedResources.getGraphFile.scopes,
						}).then((response) => {
							getOneDriveFileWithToken(response.accessToken, protectedResources.getGraphFile.endpoint, graphFile.id)
								.then(response => setGraphFileUrl(response));
						}).catch(error => {
							console.log(error);
							setIsDubbing(false);
						});
					}
				}
			});

			setGraphFile(null);
		}
	}
		, [graphFile, account, inProgress, instance])


	useEffect(() => {
		if (dubbResponse && dubbResponse.error) {
			setFileToBeDubbed(null);
			setDubbResponse(null);
			setAnError(dubbResponse.error);
			setIsDubbing(false);
		} else if (dubbResponse && dubbResponse.fileBase64) {

			const binaryFile = Buffer.from(dubbResponse.fileBase64, 'base64');

			instance.acquireTokenSilent({
				scopes: protectedResources.putGraphFile.scopes,
				account: account
			}).then((response) => {
				putOneDriveFileWithToken(response.accessToken, protectedResources.putGraphFile.endpoint, "aglabs-dubbed-resumes", dubbResponse.fileName, binaryFile)
					.then(response => setGraphFile(response));
			}).catch((error) => {
				// in case if silent token acquisition fails, fallback to an interactive method
				if (error instanceof InteractionRequiredAuthError) {
					if (account && inProgress === "none") {
						instance.acquireTokenPopup({
							scopes: protectedResources.putGraphFile.scopes,
						}).then((response) => {
							putOneDriveFileWithToken(response.accessToken, protectedResources.putGraphFile.endpoint, "aglabs-dubbed-resumes", dubbResponse.fileName, binaryFile)
								.then(response => setGraphFile(response));
						}).catch(error => {
							console.log(error);
							setIsDubbing(false);
						});
					}
				}
			});

			setFileToBeDubbed(null);
			setDubbResponse(null);
		}
	}
		, [dubbResponse, account, inProgress, instance])


	useEffect(() => {
		if (fileToBeDubbed && account && inProgress === "none") {
			setIsDubbing(true);
			setAnError(null);

			const form = new FormData();

			form.append('upload_preset', 'ujpu6gyk');
			form.append('selected_template', JSON.stringify(selectedTemplate));
			form.append('user_details', JSON.stringify(profileData));
			form.append('additional_properties', JSON.stringify({}));
			form.append('resume', fileToBeDubbed);

			console.info(form);

			instance.acquireTokenSilent({
				scopes: protectedResources.postDubResume.scopes,
				account: account
			}).then((response) => {
				postMultiPartApiWithToken(response.accessToken, protectedResources.postDubResume.endpoint, form)
					.then(response => setDubbResponse(response))
					.catch((error) => {
						console.error(error);
						setAnError(error + errorMsgAddon)
						setIsDubbing(false);
					})
			}).catch((error) => {
				// in case if silent token acquisition fails, fallback to an interactive method
				if (error instanceof InteractionRequiredAuthError) {
					if (account && inProgress === "none") {
						instance.acquireTokenPopup({
							scopes: protectedResources.postDubResume.scopes,
						}).then((response) => {
							postMultiPartApiWithToken(response.accessToken, protectedResources.postDubResume.endpoint, form)
								.then(response => setDubbResponse(response));
						}).catch(error => {
							console.error(error);
							setAnError(error + errorMsgAddon)
							setIsDubbing(false);
						});
					}
				} else {
					console.error(error);
					setAnError(error + errorMsgAddon)
					setIsDubbing(false);
				}
			});

		}
	}, [fileToBeDubbed, selectedTemplate, account, inProgress, instance, profileData])

	function Mailto({ email, subject, body, ...props }) {
		return (
			<a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
				{props.children}
			</a>
		);
	}


	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest}
		>
			<IconSettings iconPath="/assets/icons">
				{anError ?
					<AlertContainer>
						<Alert
							icon={<Icon category="utility" name="error" />}
							labels={{
								heading: anError,
							}}
							variant="error"
						/>
					</AlertContainer>
					: null}
				{isDubbing ?
					<Spinner
						size="large"
						variant="brand"
						assistiveText={{ label: 'Dubbing Resume..... please wait...' }}
					/> : null}
				<WelcomeMat
					labels={{
						title: 'Smart Resume Dubber',
						description:
							'',//'Welcome to Allegis Group Innovation Labs Resume Dubbing Tool. Select a Template on the Right, then drag a Resume to the box below or click upload.',
					}}
					/*
					leftHandChildren={<div> <Mailto email="8bd5f2e2.ALLEGISCLOUD.onmicrosoft.com@amer.teams.ms" subject="Dubber Feedback">
					Questions and Feedback, email here.
				  </Mailto><br/><br/>
									<br/><a href="https://forms.office.com/r/Yyw0HZFMdJ" rel="noreferrer" target="_blank" >Tell us how we are doing, by filling in this survey.</a>	</div>	}
					*/
					leftHandChildren={<div class="slds-progress slds-progress_vertical">
						<ol class="slds-progress__list" style={{textAlign: "left"}}>
							<li class="slds-progress__item" >
								<span class=" slds-icon-utility-success slds-progress__marker " title="Complete">
							

									<span class="slds-assistive-text">Pick a Template</span>
								</span>
								<div class="slds-progress__item_content slds-grid ">
									<p><b>Select a Template</b>&nbsp;on the&nbsp;<b>Right.</b></p>

								</div>
							</li>
							<li class="slds-progress__item ">
								<div class="slds-progress__marker">
									<span class="slds-assistive-text">Active</span>
								</div>
								<div class="slds-progress__item_content slds-grid " style={{whiteSpace: "pre-line"}} >
									<p><b>Drag a Resume</b> to the <b>Box Below</b> or<br/><b>Click Upload.</b></p>
								</div>
							</li>
							<li class="slds-progress__item ">
								<div class="slds-progress__marker">
									<span class="slds-assistive-text">Active</span>
								</div>
								<div class="slds-progress__item_content slds-grid ">
									<FileDragNDrop disabled={!selectedTemplate} fileToBedDubbed={fileToBeDubbed} setFileToBeDubbed={setFileToBeDubbed} />
								</div>
							</li>
							<li class="slds-progress__item">
								<div class="slds-progress__marker"></div>
								<div class="slds-progress__item_content slds-grid ">
									<p><b>Once Processed</b>, the <b>Dubbed Resume</b> will <b>Open in Word</b>.</p>
								</div>
							</li>
							<li class="slds-progress__item">
								<div class="slds-progress__marker"></div>
								<div class="slds-progress__item_content slds-grid ">
									<p><b>Review and Amend to Your Needs. Ensure</b> the Resume is <b>Ready for Client Presentation.</b></p>
									</div>
							</li>
							<li class="slds-progress__item">
								<div class="slds-progress__marker"></div>
								<div class="slds-progress__item_content slds-grid">
								<p><b>HELP MAKE THIS A BETTER TOOL</b><br/><Mailto email="8bd5f2e2.ALLEGISCLOUD.onmicrosoft.com@amer.teams.ms" subject="Dubber Feedback">
					Questions and Support, email here.
				  </Mailto><br/>
									<br/><a href="https://forms.office.com/r/Yyw0HZFMdJ" rel="noreferrer" target="_blank" >Tell us how we are doing, by filling in this survey.</a>	</p>	
								</div>
							</li>
						</ol>

					</div>}
					rightHandChildren={<TemplateList selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />}
				
/>
			</IconSettings>

		</MsalAuthenticationTemplate>
	)
};
